import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import Variation from '../variation'

const PortfolioList = ({ portfolio }) => {
  return !portfolio ? null : (
    <Box style={{ height: '85%', position: 'relative'}}>
      <Grid spacing={1} container style={{maxHeight: '100%', position:'relative', overflowY: 'auto'}}>
        {portfolio.instruments.map((item, index) => (
          <Grid item sm={12} key={`portfolioItem-${index}`}>
            <Paper variant='outlined'>
              <Grid container>
                <Grid item sm={1}>
                  <img style={{width: '35px', border: '1px solid #eee', borderRadius: '5px'}} src={`https://d3r4rfvivgvez7.cloudfront.net/${item.symbol.slice(0, -1)}.png`}></img>
                </Grid>
                <Grid item sm={4}>                  
                  {console.log(item)}
                  <Typography variant='h2'>{item.symbol}</Typography>
                  <Typography variant='h3'>
                    <CurrencyFormat
                      value={item.amount * item.close}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography variant='h2'>
                    <CurrencyFormat
                      value={item.amount}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      decimalScale={0}
                    />
                  </Typography>
                  <Typography variant='h3'>
                    <CurrencyFormat
                      value={item['average-price']}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography variant='h2'>
                    <Variation style={{ fontWeight: 'bold' }} value={item.close} change={item['average-price'] - item.close} />
                  </Typography>
                  <Typography variant='h3'>
                    <CurrencyFormat
                      value={item.close}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </Typography>
                </Grid>
              </Grid>
              
            </Paper>
            
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
export default PortfolioList
