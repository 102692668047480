import api from './interceptor'
export const authenticate = async (credentials) => (await api.post('/users/authenticate', credentials)).data
export const authorize = async () => {
  try {
    return (await api.get('/users/authorize')).data
  } catch(e) {
    return null
  }
}
export const signup = async () => (await api.post('/users/signup')).data
export const accessToken = () => (localStorage.getItem('access-token'))