import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import AddModal from '../../components/addModal/addModal'
import {
  requestCreateNewSimulation,
  requestDeleteSimulation
} from '../../actions/simulationActions'
import {
  Typography,
  Grid,
  Paper,
  Container,
  Button,
  Box,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import OplabList from '../../components/oplabList/oplabList'
import { requestDeletePortfolio, requestCreatePortfolio } from '../../actions/portfolioActions'

const useStyles = makeStyles(theme => {
  return {
    root: {},
    buttonProgress: {
      color: theme.palette.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    }
  }
})

const Home = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [askNewSimulation, setAskNewSimulation] = useState(false)
  const [askNewPortfolio, setAskNewPortfolio] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const createNewSimulation = name => {
    dispatch(requestCreateNewSimulation(name))
    setAskNewSimulation(false)
  }

  const deleteSimulation = (id) => {
    dispatch(requestDeleteSimulation(id))
  }

  const loadSimulation = (id) => {
    history.push(`/simulation/${id}`)
  }

  const loadPortfolio = (id) => {
    history.push(`/portfolio/${id}`)
  }

  const createNewPortfolio = name => {
    dispatch(requestCreatePortfolio(name))
    setAskNewPortfolio(false)
  }

  const deletePortfolio = (id) => {
    dispatch(requestDeletePortfolio(id))
  }

  const simulations = useSelector(({ simulations }) => {
    return simulations.data.sort(
      (a, b) => moment(b['updated-at']).valueOf() - moment(a['updated-at']).valueOf()
    )
  })

  const portfolios = useSelector(({ portfolios }) => {
    if (!portfolios.managed) return undefined
    return portfolios.managed.sort((a, b) => moment(b['updated-at']).valueOf() - moment(a['updated-at']).valueOf())
  })

  useEffect(() => {
    setIsLoading(false)
  }, [simulations, portfolios])

  return (
    <Container maxWidth={'xl'}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h1">Portfolios</Typography>
            <OplabList
              onClick={(item) => {
                loadPortfolio(item.id)
              }}
              onDelete={item => {
                setIsLoading(true)
                deletePortfolio(item.id)
              }}
              items={portfolios}
            ></OplabList>
            <Box mt={2} className={classes.wrapper}>
              <Button
                disabled={isLoading}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setAskNewPortfolio(true)
                  setIsLoading(true)
                }}
              >
                Adicionar Portfolio
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h1">Simulações</Typography>
            <OplabList
              onClick={item => {
                loadSimulation(item.id)
              }}
              onDelete={item => {
                deleteSimulation(item.id)
                setIsLoading(true)
              }}
              items={simulations}
            ></OplabList>
            <Box mt={2} className={classes.wrapper}>
              <Button
                disabled={isLoading}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setAskNewSimulation(true)
                  setIsLoading(true)
                }}
              >
                Adicionar Simulação
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Box>
          </Paper>
        </Grid>
        
      </Grid>
      <AddModal
        label="Simulação"
        open={askNewSimulation}
        onDismiss={() => {
          setAskNewSimulation(false)
          setIsLoading(false)
        }}
        onConfirm={name => {
          createNewSimulation(name)
        }}
      />
      <AddModal
        label="Portfolio"
        open={askNewPortfolio}
        onDismiss={() => {
          setAskNewPortfolio(false)
          setIsLoading(false)
        }}
        onConfirm={name => {
          createNewPortfolio(name)
        }}
      />
    </Container>
  )
}

export default withRouter(Home)
