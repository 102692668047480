import { call, put, takeLatest } from 'redux-saga/effects'
import { list, create,  remove, addInstrument, updateInstrument, removeInstrument } from '../services/portfolioServices'
import {
  REQUEST_MANAGED_PORTFOLIOS,
  REQUEST_CREATE_MANAGED_PORTFOLIO,
  REQUEST_DELETE_MANAGED_PORTFOLIO,
  ADD_INSTRUMENT_TO_PORTFOLIO,
  UPDATE_INSTRUMENT_TO_PORTFOLIO,
  DELETE_INSTRUMENT_TO_PORTFOLIO
} from '../constants/portfolioConstants'
import {
  fulfillPortfolios,
  addPortfolioToList,
  removePortfolioFromList,
  updatePortfolioOnList
} from '../actions/portfolioActions'


function* listPortfolios(type, callback) {
  try {
    const portfolios = yield call(list, type)
    if (portfolios) yield put(callback(portfolios))
  } catch (e) {
    console.log('merda', e)
  }
}

function* createPortfolio(name, type, callback) {
  try {
    const portfolio = yield call(create, { portfolio: { name, type } })
    if (portfolio && callback) yield put(callback(portfolio))
    return portfolio
  } catch (e) {
    //TODO show a toast or something else
    console.log('merda', e)
  }
}

function* deletePortfolio(id, callback) {
  try {
    yield call(remove, id)
    yield put(callback(id))
  } catch (e) {
    //TODO show a toast or something else
    console.log('merda', e)
  }
}

function* listManagedPortfolios() {
  yield* listPortfolios('managed', fulfillPortfolios)
}

function* createManagedPortfolio({ name }) {
  yield* createPortfolio(name, 'ManagedPortfolio', addPortfolioToList)
}

function* deleteManagedPortfolio({ id }) {
  yield* deletePortfolio(id, removePortfolioFromList)
}


function* insertInstrumentToPortfolio({portfolioId, selectedAsset}){
  const portfolio = yield call(addInstrument, portfolioId, selectedAsset)
  yield put(updatePortfolioOnList(portfolio))
}

function* updateInstrumentToPortfolio({portfolioId, selectedAsset}){
  const portfolio = yield call(updateInstrument, portfolioId, selectedAsset)
  yield put(updatePortfolioOnList(portfolio))
}

function* deleteInstrumentToPortfolio({portfolioId, selectedAsset: { symbol }}){
  const portfolio = yield call(removeInstrument, portfolioId, symbol)
  yield put(updatePortfolioOnList(portfolio))
}

export default [
  takeLatest(REQUEST_MANAGED_PORTFOLIOS, listManagedPortfolios),
  takeLatest(REQUEST_CREATE_MANAGED_PORTFOLIO, createManagedPortfolio),
  takeLatest(REQUEST_DELETE_MANAGED_PORTFOLIO, deleteManagedPortfolio),
  takeLatest(ADD_INSTRUMENT_TO_PORTFOLIO, insertInstrumentToPortfolio),
  takeLatest(DELETE_INSTRUMENT_TO_PORTFOLIO, deleteInstrumentToPortfolio),
  takeLatest(UPDATE_INSTRUMENT_TO_PORTFOLIO, updateInstrumentToPortfolio)
]
