import MuiButton from './MuiButton'
import MuiPaper from './MuiPaper'
import MuiAlert from './MuiAlert'
import MuiTypography from './MuiTypography'
import MuiTextField from './MuiTextField'
import MuiInputLabel from './MuiInputLabel'
import MuiListItemText from './MuiListItemText'
import MuiMenuItem from './MuiMenuItem'
import MuiSlider from './MuiSlider'
import MuiTab from './MuiTab'

export default {
  MuiButton,
  MuiPaper,
  MuiTypography,
  MuiTextField,
  MuiInputLabel,
  MuiAlert,
  MuiListItemText,
  MuiMenuItem,
  MuiSlider,
  MuiTab
}
