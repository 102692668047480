import {
  REQUEST_MANAGED_PORTFOLIOS,
  FULLFILL_MANAGED_PORTFOLIOS,
  ADD_MANAGED_PORTFOLIO_TO_LIST,
  REQUEST_CREATE_MANAGED_PORTFOLIO,
  REQUEST_DELETE_UNMANAGED_PORTFOLIO,
  REMOVE_MANAGED_PORTFOLIO_FROM_LIST,
  UPDATE_MANAGED_PORTFOLIO_ON_LIST,
  ADD_INSTRUMENT_TO_PORTFOLIO,
  UPDATE_INSTRUMENT_TO_PORTFOLIO,
  DELETE_INSTRUMENT_TO_PORTFOLIO,
  UPDATE_UNMANAGED_INITIAL_VALUE,
  REQUEST_DELETE_MANAGED_PORTFOLIO
} from '../constants/portfolioConstants'

export const fulfillPortfolios = portfolios => ({
  type: FULLFILL_MANAGED_PORTFOLIOS,
  portfolios
})

export const requestPortfolios = () => ({ type: REQUEST_MANAGED_PORTFOLIOS })

export const requestCreatePortfolio = name => ({
  type: REQUEST_CREATE_MANAGED_PORTFOLIO,
  name
})

export const addPortfolioToList = portfolio => ({
  type: ADD_MANAGED_PORTFOLIO_TO_LIST,
  portfolio
})

export const requestDeleteUnmanagedPortfolio = id => ({
  type: REQUEST_DELETE_UNMANAGED_PORTFOLIO,
  id
})

export const requestDeletePortfolio = id => ({
  type: REQUEST_DELETE_MANAGED_PORTFOLIO,
  id
})

export const updatePortfolioInitialValue = (id, value) => ({
  type: UPDATE_UNMANAGED_INITIAL_VALUE,
  id,
  value
})

export const removePortfolioFromList = id => ({
  type: REMOVE_MANAGED_PORTFOLIO_FROM_LIST,
  id
})

export const updatePortfolioOnList = portfolio => ({
  type: UPDATE_MANAGED_PORTFOLIO_ON_LIST,
  portfolio
})

export const addInstrumentToPortfolio = (portfolioId, selectedAsset) => ({
  type: ADD_INSTRUMENT_TO_PORTFOLIO,
  portfolioId,
  selectedAsset
})

export const updateInstrumentOnPortfolio = (portfolioId, selectedAsset) => ({
  type: UPDATE_INSTRUMENT_TO_PORTFOLIO,
  portfolioId,
  selectedAsset
})

export const deleteInstrumentOnPortfolio = (portfolioId, selectedAsset) => ({
  type: DELETE_INSTRUMENT_TO_PORTFOLIO,
  portfolioId,
  selectedAsset
})
