import palette from '../palette'

export default {
  root: {
    height: '6rem',
    width: '100%',
    borderColor: palette.colors.white
  },

}
