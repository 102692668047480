import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  currency: {
    fontWeight: 'bold'
  }
}))

const green = {
  color: '#21BA45'
}

const red = {
  color: '#DB2828'
}

const Variation = ({ change, value, variation }) => {

  const classes = useStyles()

  const percentage = () => {
    if (variation) return variation
    const p = (change / value) * 100
    return Number.isFinite(p) && !Number.isNaN(p) ? p : 0
  }

  return (
    <span className={classes.currency} style={change < 0 ? red : green}>
      {change >= 0 ? '+' : null}
      <CurrencyFormat
        value={variation ? value - 100 * value / (100 + variation) : change}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        fixedDecimalScale={true}
        decimalScale={2}
      />
      <span className="percentage">
        ({change >= 0 ? '+' : null}
        {Number(percentage())
          .toFixed(2)
          .replace('.', ',')}
        %)
      </span>
    </span>
  )
}

export default Variation
