import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

class NumberInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValue: props.value,
      dirty: false,
      value: { raw: props.value, formatted: this.toMask(props.value) } //undefined
    }

    this.mask = null

    if (this.props.currency) {
      this.mask = createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        decimalSymbol: ',',
        thousandsSeparatorSymbol: '.',
        decimalLimit: this.props.decimal || 2,
        requireDecimal: false,
        allowDecimal: true
      })
    } else if (this.props.percentage) {
      this.mask = createNumberMask({
        prefix: '',
        suffix: ' %',
        decimalSymbol: ',',
        thousandsSeparatorSymbol: '.',
        decimalLimit: this.props.decimal || 2,
        allowDecimal: true
      })
    } else {
      this.mask = createNumberMask({
        prefix: '',
        suffix: '',
        decimalSymbol: ',',
        thousandsSeparatorSymbol: '.',
        decimalLimit: 0,
        allowDecimal: false
      })
    }

    this.isInput = this.props.input
  }

  updateValue(value) {
    return new Promise((resolve, reject) =>
      this.setState({ dirty: true, value: { raw: this.fromMask(value), formatted: value } }, () =>
        resolve(this.state.value)
      )
    )
  }

  handleChange(e) {
    this.updateValue(e.target.value).then(value => {
      if (this.props.onChange) this.props.onChange(e, value)
    })
  }

  valueSelected(value) {
    if (value) {
      this.updateValue(value).then(value => {
        if (this.props.onValue) this.props.onValue(value)
      })
    } else
      this.setState({ dirty: false }, () => {
        if (this.props.onValue) this.props.onValue(this.state.value)
      })
  }

  toMask(number) {
    if (number === undefined) return ''

    if (this.props.currency) {
      return (
        'R$ ' +
        new Intl.NumberFormat('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: 2
        }).format(number)
      )
    } else if (this.props.percentage) {
      return (
        new Intl.NumberFormat('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: 0
        }).format(number) + ' %'
      )
    }
    return number.toString()
  }

  componentWillReceiveProps(props) {
    if (props.value !== this.state.initialValue) this.setState({ initialValue: props.value })
    this.setState({
      value: { raw: props.value, formatted: this.toMask(props.value) }
    })
  }

  shouldComponentUpdate() {
    return !this.state.dirty
  }

  fromMask(value) {
    if (value === undefined || null === value) return ''
    else if (value.match(/^\s*$/)) return 0
    else return Number.parseFloat(value.replace(/[^\d,]/g, '').replace(/,/g, '.'))
  }

  render() {
    let masked = (
      <MaskedInput
        mask={this.mask}
        style={{ ...this.props.style }}
        placeholder={this.props.placeHolder}
        guide={false}
        value={this.state.value.formatted}
        onChange={e => this.handleChange(e)}
        onKeyDown={e => {
          if (e.which === 13) {
            e.preventDefault()
            e.stopPropagation()
            this.valueSelected(null)
          }
        }}
        className={this.props.className}
        readOnly={this.props.readOnly || false}
        onBlur={e => {
          e.preventDefault()
          e.stopPropagation()
          this.valueSelected(e.target.value)
        }}
      />
    )

    if (this.isInput) return masked
    else return masked
  }
}

export default NumberInput
