import React, { useState } from 'react'
import PortfolioList from '../../components/portfolioList/portfolioList'
import { useSelector } from 'react-redux'
import OrderHistory from '../../components/orderHistory/orderHistory'
import BuySell from '../../components/buySell/buySell'
import { Grid, Tab, Paper, Typography, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PortfolioSummary from '../../components/portfolioSummary/portfolioSummary'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  smallerFont: {
    fontSize: '1.5rem'
  },
  slider: {
    padding: '2rem'
  },
  numberInput: {
    fontSize: '1em',
    color: theme.palette.colors.lightPurple,
    outline: 'none',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: `1px solid ${theme.palette.colors.lightPurple}`
  }
}))

const Portfolio = (props) => {
  const classes = useStyles()
  const portfolio = useSelector(store => {
    return store.portfolios.managed.find(p => p.id === Number(props.match.params.id))
  })

  const tabs = [
    { label: 'Portfolio', render: <PortfolioList portfolio={portfolio}></PortfolioList> },
    { label: 'Ordens', render: <OrderHistory portfolio={portfolio}></OrderHistory> },
    { label: 'Boleta', render: <BuySell portfolio={portfolio}></BuySell>},
  ]

  const [selectedTab, setSelectedTab] = useState(0)
  
  return (
    <div className={classes.root}>
      <Grid spacing={2}  container>
        <Grid item xs={4}>
          <Paper>
            <Typography variant='h2' style={{marginBottom: '1em'}}>Sumário</Typography>
            <PortfolioSummary portfolio={portfolio}/>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <Typography variant='h2'>Balanceamentos</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Tabs indicatorColor="primary" textColor="primary" value={selectedTab} onChange={(...args) => setSelectedTab(args[1])}>
                  {tabs.map(tab => <Tab key={`tab-${tab.label}`} label={tab.label} />)}                  
                </Tabs>
                {tabs[selectedTab].render}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Portfolio