export const REQUEST_SIMULATION = Symbol('REQUEST_SIMULATION')
export const REQUEST_SIMULATIONS = Symbol('REQUEST_SIMULATIONS')
export const CALCULATE_SIMULATION = Symbol('CALCULATE_SIMULATION')
export const CHANGE_SIMULATION_INTERVAL = Symbol('CHANGE_SIMULATION_INTERVAL')
export const FULLFILL_SIMULATION = Symbol('FULLFILL_SIMULATION')
export const FULFILL_SIMULATIONS = Symbol('FULFILL_SIMULATIONS')
export const FULFILL_LOCAL_SIMULATION = Symbol('FULFILL_LOCAL_SIMULATION')
export const REQUEST_CREATE_NEW_SIMULATION = Symbol('REQUEST_CREATE_NEW_SIMULATION')
export const REQUEST_DELETE_SIMULATION = Symbol('REQUEST_DELETE_SIMULATION')
export const UPDATE_SIMULATION_PARAMETERS = Symbol('UPDATE_SIMULATION_PARAMETERS')

