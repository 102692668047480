import palette from './dark/palette'
import overrides from './dark/overrides'

import lightPalette from './light/palette'
import lightOverrides from './light/overrides'

import typography from './typography'

import NotoSans from '../fonts/NotoSans-Regular.ttf'

const MuiCssBaseline= {
  '@global': {
    '@font-face': [{
      fontFamily: 'Noto Sans',
      src: `
        local('Noto Sans'),
        url(${NotoSans})
        format('truetype')
      `
    }],
  },
}

const MuiInputBase = {
  input:{
    fontSize: '1.5rem'
  }
}

export const light = {
  type: 'light',
  palette: lightPalette,
  typography: typography,
  overrides: {
    ...lightOverrides,
    MuiCssBaseline,
    MuiOutlinedInput: {
      input: {
        color: palette.colors.steel
      },
      notchedOutline: {
        borderColor: palette.colors.lightSmoke
      }
    },
    PrivateNotchedOutline: {
      legendLabelled: {
        width: '4.5rem'
      }
    },
    MuiInputBase
  }
}

export const dark = {
  type: 'dark',
  palette,
  typography,
  overrides: {
    ...overrides,
    MuiCssBaseline,
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor:'#f00'
      }
    }
  }
}

export default light
