import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../containers/home/home'
import Simulation from '../containers/simulation/simulation'
import Portfolios from '../containers/portfolios/portfolios'
import Portfolio from '../containers/portfolio/portfolio'


const PrivateRouter = () => {
  return (
    <Switch>
      <Route path="/portfolios" component={Portfolios} />
      <Route path="/simulation/:id" render={props => <Simulation {...props} />} />
      <Route path="/portfolio/:id" render={props => <Portfolio {...props} />} />
      <Route exact={true} path="/" component={Home} />
      <Route component={null} />
    </Switch>
  )
}
export default PrivateRouter
