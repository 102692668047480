import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import sagas from '../sagas/'
import reducers from '../reducers'

function* rootSaga() {
  yield all([...sagas])
}

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const saga = createSagaMiddleware()

const combReducers = combineReducers({
  router: connectRouter(history),
  ...reducers
})

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

let middlewares
if ((module.hot && process.env.NODE_ENV !== 'production') || process.env.CI === true) {
  const logger = require('redux-logger').default
  middlewares = [routeMiddleware, logger, saga]
} else {
  middlewares = [routeMiddleware, saga]
}

const store = createStore(combReducers, composeEnhancers(applyMiddleware(...middlewares)))

if (module.hot && process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  module.hot.accept('../reducers', () => {
    store.replaceReducer(reducers)
  })
}

saga.run(rootSaga)

export { store, history }
