import React, { useState, useEffect } from 'react'
import { search } from '../../services/instrumentServices'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress, Box, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'


const useStyles = makeStyles((theme) => ({
  backgroundList: {
    fontSize: '2rem',
    color: theme.palette.colors.gray,
    backgroundColor: theme.palette.colors.white,
    border: `1px solid ${theme.palette.colors.lightSmoke}`,
  },
  outlined: {
    color: 'red'
  },
  search: {
    color: theme.palette.lightPurple
  }
}))

const OptionList = (props) => {
  const classes = useStyles()
  return <Box {...props} className={classes.backgroundList} >
  </Box>
}

const SearchInstrument = ({ categories, onSelected, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const types = (categories || ['Stock', 'Bond']).join()

  const reduce = instruments => {
    setResults(instruments)
  }

  const handleSelectInstrument = (event, value) => {
    if (onSelected) onSelected(value)
    setQuery('')
    setLoading(false)
  }

  useEffect(() => {
    if (query) {
      setLoading(true)
      search(query, types).then(data => reduce(data))
    }
  }, [query, types])

  useEffect(() => {
    setLoading(false)
  }, [results])
  const classes = useStyles()

  return (
    <Autocomplete 
      style={{border: ''}}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        if (query.length < 1) {
          setLoading(false)
        }
        setOpen(false)
      }}
      loading={loading}
      options={results}
      popupIcon={<SearchIcon/>}
      onInputChange={({target: {value}}) => {
        if (loading || !value || value.length < 3 ) {
          return undefined
        }
        setLoading(true)
        setQuery(value)
      }}
      onChange={handleSelectInstrument}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.symbol}
      forcePopupIcon={!open}
      loadingText={'Carregando'}
      noOptionsText={'Não Encontrado'}
      openText={'Buscar'}
      PaperComponent={OptionList}
      renderOption={(option)=> `${option.symbol} - ${option.description}`}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          classes={{root: classes.outlined}}
          className='search'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    >
    </Autocomplete>
  )
}

export default SearchInstrument