import { FULFILL_LOCAL_SIMULATION } from '../constants/simulationConstants'

export default (state = {}, action) => {
  const { type } = action
  switch (type) {
  case FULFILL_LOCAL_SIMULATION:
    return action.localSimulation
  default:
    return { ...state }
  }
}
