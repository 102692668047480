import {
  FULLFILL_MANAGED_PORTFOLIOS,
  FULLFILL_UNMANAGED_PORTFOLIOS,
  ADD_UNMANAGED_PORTFOLIO_TO_LIST,
  ADD_MANAGED_PORTFOLIO_TO_LIST,
  REMOVE_UNMANAGED_PORTFOLIO_FROM_LIST,
  REMOVE_MANAGED_PORTFOLIO_FROM_LIST,
  UPDATE_MANAGED_PORTFOLIO_ON_LIST,
  UPDATE_UNMANAGED_PORTFOLIO_ON_LIST,
  UPDATE_UNMANAGED_INITIAL_VALUE
} from '../constants/portfolioConstants'

export default (state = { managed: [], unmanaged: [] }, action) => {
  switch (action.type) {
  case FULLFILL_MANAGED_PORTFOLIOS:
    return { ...state, managed: action.portfolios }
  case FULLFILL_UNMANAGED_PORTFOLIOS:
    return { ...state, unmanaged: action.portfolios.map((portfolio) => ({...portfolio, initialValue: 10000})) }
  case ADD_UNMANAGED_PORTFOLIO_TO_LIST:
    return { ...state, unmanaged: [...state.unmanaged, action.portfolio] }
  case ADD_MANAGED_PORTFOLIO_TO_LIST:
    console.log(action)
    return { ...state, managed: [...state.managed, action.portfolio] }
  case REMOVE_UNMANAGED_PORTFOLIO_FROM_LIST:
    return {
      ...state,
      unmanaged: [...state.unmanaged].filter(portfolio => portfolio.id !== action.id)
    }
  case REMOVE_MANAGED_PORTFOLIO_FROM_LIST:
    return {
      ...state,
      managed: [...state.managed].filter(portfolio => portfolio.id !== action.id)
    }
  case UPDATE_UNMANAGED_PORTFOLIO_ON_LIST:
    return {
      ...state,
      unmanaged: [...state.unmanaged].map(portfolio =>
        portfolio.id === action.portfolio.id ? { ...action.portfolio, initialValue: 10000 } : { ...portfolio, initialValue: 10000 }
      )
    }
  case UPDATE_MANAGED_PORTFOLIO_ON_LIST:
    return {
      ...state,
      managed: [...state.managed].map(portfolio =>
        portfolio.id === action.portfolio.id ? { ...action.portfolio } : { ...portfolio}
      )
    }
  case UPDATE_UNMANAGED_INITIAL_VALUE:
    console.log(action.value)
    return {
      ...state,
      unmanaged: [...state.unmanaged].map(portfolio => {
        return portfolio.id === action.id ? {...portfolio, initialValue: action.value} : portfolio
      })
    }
  default:
    return { ...state }
  }
}
