import api from './interceptor'
import { format } from 'date-fns'

export const search = async (query, types) => {
  try {
    return (await api.get(`/charts/instruments/search/${query}?type=${types}`)).data
  } catch (e) {
    return []
  }
}

export const getChartData = async (symbol, from, to) =>
  await api.get(
    `/charts/data/${symbol}/1d?from=${format(new Date(from), 'yyyyMMdd0000')}&to=${format(
      new Date(to),
      'yyyyMMdd0000'
    )}&fill=business_days`
  )

export const getSymbolData = async (symbol) => (await api.get(`/data/quote?tickers=${symbol}`)).data[0]