import React from 'react'
import { makeStyles, Slider, Box } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  bar: {
    height: 9,
    width: 0,
    border: '1px solid #471053'
  }
}))

export default function Fatslider(props) {
  const classes = useStyles()
  function lineThumbComponent(props) {
    return (
      <span {...props}>
        <span className={classes.bar} />
      </span>
    )
  }
  function ValueLabelComponent(props) {
    const { children, open, value } = props

    return (
      <Box open={open} placement="top" title={value}>
        {children}
      </Box>
    )
  }
  return (
    <Slider
      value={props.balance}
      min={1}
      max={props.max || 100}
      ValueLabelComponent={ValueLabelComponent}
      valueLabelDisplay="auto"
      ThumbComponent={lineThumbComponent}
      onChange={props.onChange}
      onChangeCommitted={props.onChangeCommitted}
    />
  )
}
