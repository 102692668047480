import { call, put, takeLatest } from 'redux-saga/effects'
import { authenticate, authorize, signup } from '../services/loginServices'
import {
  AUTHENTICATE_LOGIN,
  AUTHORIZE_LOGIN,
  SIGNUP,
  FULFILL_LOGIN,
  LOGOUT
} from '../constants/loginConstants'
import { fulfillLogin, logout, loginFailed } from '../actions/loginActions'
import { push } from 'connected-react-router'

function* authenticateHandler({ credentials }) {
  try {
    const login = yield call(authenticate, credentials)
    if (login) {
      yield put(fulfillLogin(login))
      yield put(push('/'))
    }
  } catch (e) {
    yield put(loginFailed())
  }
}

function* authorizeHandler({ shouldRedirect = false }) {
  const login = yield call(authorize)
  if (login) {
    yield put(fulfillLogin(login))
    if (shouldRedirect) {
      yield put(push('/'))
    }
  } else {
    yield put(logout())
  }
}

function* signupHandler({ form }) {
  const login = yield call(signup, form)
  if (login) {
    yield put(fulfillLogin(login))
    yield put(push('/'))
  }
}

function* loggedIn({ login }) {
  yield localStorage.setItem('access-token', login['access-token'])
  // yield console.log('TODO: start real time datafeed', login['datafeed-access-token']) //FIXME
}

function* clearLogin() {
  yield localStorage.removeItem('access-token')
}

export default [
  takeLatest(AUTHENTICATE_LOGIN, authenticateHandler),
  takeLatest(AUTHORIZE_LOGIN, authorizeHandler),
  takeLatest(SIGNUP, signupHandler),
  takeLatest(FULFILL_LOGIN, loggedIn),
  takeLatest(LOGOUT, clearLogin)
]
