import React, { useReducer, useState } from 'react'
import NumberInput from '../numberInput/numberInput'
import SearchInstrument from '../searchInstrument/searchInstrument'
import Variation from '../variation'
import CurrencyFormat from 'react-currency-format'
import { getSymbolData } from '../../services/instrumentServices'
import { Grid, Button, Paper, Chip, Typography, makeStyles, CircularProgress, Snackbar } from '@material-ui/core'
import { createOrder } from '../../services/orderServices'
import MuiAlert from '@material-ui/lab/Alert'



const FULFILL_ASSET = Symbol('FULFILL_ASSET')

function reducer(state = {}, { type, asset }) {
  switch (type) {
  case FULFILL_ASSET:
    console.log(asset)
    return { ...asset }
  default:
    return state
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2rem, 4rem'
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  smallerFont: {
    fontSize: '1.5rem'
  },
  slider: {
    padding: '2rem'
  },
  numberInput: {
    fontSize: '2em',
    textAlign: 'right',
    width: '100%',
    color: theme.palette.colors.lightPurple,
    outline: 'none',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: `1px solid ${theme.palette.colors.lightPurple}`
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}))

const OrderMechanism = ({ onAccept, type, loading }) => {
  const classes = useStyles()
  const [asset, dispatch] = useReducer(reducer, { close: 0, open: 0, variation: 0 })
  const [price, setPrice] = useState(0)
  const [amount, setAmount] = useState(100)
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h2'}>{type === 'buy' ? 'Comprar' : 'Vender'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchInstrument
            categories={['Stock', 'Bond']}
            onSelected={async ({symbol}) => {
              const data = await getSymbolData(symbol)
              dispatch({ type: FULFILL_ASSET, asset: data }) //TODO: faz sentido isso?
              setPrice(data.close)
            }}
            minCharacters={3}
            placeholder={
              asset.symbol
                ? asset.symbol
                : `Selecione um ativo para ${type === 'buy' ? 'comprar' : 'vender'}`
            }
            input={{ fluid: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <Chip
            style={{ marginRight: 8, fontSize: '1.2em' }}
            size="medium"
            label={
              <div>
                Último:{' '}
                <CurrencyFormat
                  value={asset.close}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'R$ '}
                  style={{ fontWeight: 'bold' }}
                />
              </div>
            }
          />
          <Chip
            size="medium"
            style={{ marginRight: 8, fontSize: '1.2em' }}
            label={
              <div>
                % 24h: <Variation style={{ fontWeight: 'bold' }} value={asset.close} variation={asset.variation} />
              </div>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h4'}>Preço</Typography>
          <NumberInput
            className={classes.numberInput}
            value={price}
            currency
            onValue={({ raw }) => {
              setPrice(raw)
            }}
          ></NumberInput>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h4'}>Quantidade</Typography>
          <NumberInput
            className={classes.numberInput}
            value={amount}
            onValue={({ raw }) => {
              setAmount(raw)
            }}
          ></NumberInput>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h4'}>Total</Typography>
          <Typography variant={'h3'}>
            <CurrencyFormat
              value={price * amount}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'R$ '}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={loading || !asset.symbol || price <= 0}
            variant="contained"
            onClick={() => {
              onAccept({asset, price, amount})
            }}
            color="primary"
            // style={{ color: 'white', backgroundColor: type === 'buy' ? '#2ADC26' : '#F8144A' }}
          >
            {' '}
            {type === 'buy' ? 'Comprar' : 'Vender'}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const BuySell = ({portfolio}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceed, setHasSucceed] = useState(false)

  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <Paper variant="outlined">
          <Snackbar open={hasFailed}>
            <Alert severity="error">Houve um erro ao enviar a ordem!</Alert>
          </Snackbar>
          <Snackbar open={hasSucceed}>
            <Alert severity="success">Ordem enviada com sucesso!</Alert>
          </Snackbar>

          <OrderMechanism
            type={'buy'}
            loading={isLoading}
            onAccept={({asset: { symbol }, price, amount}) => {
              setIsLoading(true)
              createOrder(portfolio.id, { symbol, amount, price ,action: 'buy', brokerage: 0, order_type: 'buySell', status: 'executed' }).then((res) => {
                setIsLoading(false)
                setHasSucceed(true)
                setTimeout(()=> setHasSucceed(false), 3000)
              }).catch((err) => {
                setIsLoading(false)
                setHasFailed(true)
                setTimeout(() => setHasFailed(false), 3000)
              })
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper variant="outlined">
          <OrderMechanism
            type={'sell'}
            loading={isLoading}
            onAccept={({asset: { symbol }, price, amount}) => {
              setIsLoading(true)
              createOrder(portfolio.id, { symbol, amount, price ,action: 'sell', brokerage: 0, order_type: 'buySell', status: 'executed' }).then((res) => {
                setIsLoading(false)
                setHasSucceed(true)
                setTimeout(()=> setHasSucceed(false), 3000)
              }).catch((err) => {
                setIsLoading(false)
                setHasFailed(true)
                setTimeout(() => setHasFailed(false), 3000)
              })
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BuySell
