import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  makeStyles,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  FormControlLabel,
  Switch,
  Divider,
  Tooltip
} from '@material-ui/core'
import logo from '../../images/logo.png'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(12)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  appBar: {
    padding: theme.spacing(0.5, 5)
  },
  logo: {
    width: theme.spacing(8)
  },
  title: {
    flexGrow: 1
  },
  avatar: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.lightPurple
  },
  menu: {
    padding: 0,
    margin: 0
  },
  tooltip: {
    fontSize: '1.5rem'
  },
  divider: {
    margin: 'auto 2rem auto 3rem',
    height: '2rem',
    background: theme.palette.colors.lightSmoke,
    opacity: 0.5
  }
}))

export const NavBar = () => {
  const login = useSelector(({ login }) => login)
  const location = useSelector(({ router }) => router.location.pathname)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isDark, setIsDark] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  return (
    <AppBar elevation={0} className={classes.appBar}>
      <Toolbar>
        <Typography className={classes.title} edge={'start'} variant="h6" color="inherit">
          <Box>
            <img alt="Oplab Logo" className={classes.logo} src={logo}></img>
          </Box>
        </Typography>
        {/* <Typography variant="h2" color="inherit">Portfolio: <b>Hello World</b></Typography>
        <Divider className={classes.divider} flexItem orientation="vertical"/> */}
        <Tooltip classes={{ tooltip: classes.tooltip }} title={login.name}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={event => {
              setAnchorEl(event.target)
            }}
          >
            <Avatar className={classes.avatar}>
              {login.name.split(' ').map((current, i) => (i < 2 ? current[0] : ''))}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          classes={{ paper: classes.menu }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <MenuItem>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={isDark}
                  onChange={() => {
                    setIsDark(!isDark)
                  }}
                />
              }
              label="Dark"
            />
          </MenuItem>
          <Divider />
          <MenuItem
            selected={Boolean(location.match(/^\/$/))}
            onClick={() => {
              history.push('/')
            }}
          >
            Home
          </MenuItem>
          <MenuItem selected={Boolean(location.match(/^\/settings$/))} onClick={() => {}}>
            Configurações
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              history.push('/logout')
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
