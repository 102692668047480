import React, { useEffect, useState } from 'react'
import { fetchOrders } from '../../services/orderServices'
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'

const OrderHistory = ({portfolio = null}) => {
  const [orders, setOrders] = useState([])
  useEffect(() => {
    fetchOrders(portfolio.id).then((orders) => setOrders(orders))
  }, [portfolio.id])
  return (
    <div style={{ maxHeight: '270px', position: 'relative', overflowY: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant={'h5'}>Ativo</Typography></TableCell>
            <TableCell><Typography variant={'h5'}>Tipo</Typography></TableCell>
            <TableCell><Typography variant={'h5'}>Preço</Typography></TableCell>
            <TableCell><Typography variant={'h5'}>Quantidade</Typography></TableCell>
            <TableCell><Typography variant={'h5'}>Total</Typography></TableCell>
            <TableCell><Typography variant={'h5'}>Status</Typography></TableCell>
            <TableCell><Typography variant={'h5'}>Data</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...orders].map((o, i) => (
            <TableRow key={`orderRow-${i}`}>
              <TableCell><Typography variant={'h4'}>{o.symbol}</Typography></TableCell>
              <TableCell><Typography variant={'h4'}>{o.type === 'buy' ? 'C' : 'V'}</Typography></TableCell>
              <TableCell><Typography variant={'h4'}>{o.price}</Typography></TableCell>
              <TableCell><Typography variant={'h4'}>{o.amount}</Typography></TableCell>
              <TableCell><Typography variant={'h4'}>{Math.round(o.price * o.amount)}</Typography></TableCell>
              <TableCell><Typography variant={'h4'}>{o.status === 'executed' ? 'Executado' : o.status}</Typography></TableCell>
              <TableCell><Typography variant={'h4'}>{o.date}</Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default OrderHistory
