import { dark, light } from '../theme/index'
import { createMuiTheme } from '@material-ui/core'
import { TOGGLE_THEME } from '../constants/themeConstants'

const darkTheme = createMuiTheme(dark)
const lightTheme = createMuiTheme(light)

console.log(lightTheme)

export default (state = lightTheme, action) => {
  switch (action.type) {
  case TOGGLE_THEME:
    return state.type === 'dark' ? lightTheme : darkTheme
  default:
    return state
  }

}