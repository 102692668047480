import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './portfolios.css'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { requestCreatePortfolio, requestDeletePortfolio } from '../../actions/portfolioActions'
// // // import NewPortfolioForm from '../../components/newPortfolioForm/newPortfolioForm'

const noBorder = {
  border: 'none'
}

const Portfolios = ({ history }) => {
  const dispatch = useDispatch()

  const [askNew, setAskNew] = useState(false)

  const createNewPortfolio = name => {
    dispatch(requestCreatePortfolio(name))
    setAskNew(false)
  }

  const deletePortfolio = (id) => {
    dispatch(requestDeletePortfolio(id))
  }


  const loadPortfolio = (id) => {
    history.push(`/portfolio/${id}`)
  }

  const portfolios = useSelector(({ portfolios }) => {
    if (!portfolios.managed) return undefined
    return portfolios.managed.sort((a, b) => moment(b['updated-at']).valueOf() - moment(a['updated-at']).valueOf())
  })

  return (
    // <Grid container style={{ height: '100%', margin: 0 }} columns={1}>
    //   <Grid.Column>
    //     <Header
    //       as="h2"
    //       content="Meus Portfolios"
    //       style={{ marginTop: '1rem', marginBottom: '3rem' }}
    //     />

    //     {portfolios && portfolios.length > 0 ? (
    //       <Table basic="very" selectable>
    //         <Table.Body>
    //           {portfolios.map((portfolio, index) => {
    //             return (
    //               <Table.Row key={index}>
    //                 <Table.Cell
    //                   style={{ ...noBorder, cursor: 'pointer' }}
    //                   onClick={() => loadPortfolio(portfolio.id)}
    //                 >
    //                   <Header as="h4">
    //                     <Icon
    //                       inverted
    //                       color="black"
    //                       name={'lab'}
    //                       size="mini"
    //                     />
    //                     <Header.Content>
    //                       {portfolio.name}
    //                       <Header.Subheader>
    //                         <i>
    //                           Criado em{' '}
    //                           {moment(portfolio['created-at']).format('DD-MM-YYYY[ às ]HH:mm')}
    //                         </i>
    //                       </Header.Subheader>
    //                     </Header.Content>
    //                   </Header>
    //                 </Table.Cell>
    //                 <Table.Cell style={noBorder}>
    //                   <Icon
    //                     name="trash"
    //                     style={{ cursor: 'pointer' }}
    //                     onClick={() => deletePortfolio(portfolio.id)}
    //                   />
    //                 </Table.Cell>
    //               </Table.Row>
    //             )
    //           })}
    //         </Table.Body>
    //       </Table>
    //     ) : (
    //       <Message visible>Nenhum portfolio encontrado</Message>
    //     )}
    //     <p>
    //       <a href="/#" onClick={() => {
    //         setAskNew(true)
    //       }}>
    //         Criar novo portfolio
    //       </a>
    //     </p>
    //   </Grid.Column>

    // </Grid>
    'ko'
  )
}

export default withRouter(Portfolios)
