import React, { useState } from 'react'
import {
  Modal,
  TextField,
  Fade,
  Paper,
  makeStyles,
  Typography,
  Button,
  Box
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(4)
  }
}))

const AddModal = ({ open, label, oldName, onConfirm, onDismiss }) => {
  const [name, setName] = useState(oldName || '')
  const classes = useStyles()
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={()=>{onDismiss()}}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant={'h1'}>Adicionar {label}</Typography>
          <form noValidate autoComplete="off" size="large">
            <TextField
              autoFocus={true}
              margin="normal"
              name="name"
              onChange={({target}) => {
                setName(target.value)
              }}
              label="Nome"
              value={name}
            />
            <Box mt={2}>
              <Button color="primary" variant="outlined" onClick={() => {
                onConfirm(name)
              }}>
                Adicionar
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default AddModal
