import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import SearchInstrument from '../searchInstrument/searchInstrument'

const useStyles = makeStyles(theme => ({
  smallerFont: {
    fontSize: '1.5rem'
  },

  bottomMargin: {
    marginBottom: theme.spacing(3)
  }
}))

const SimulationInstrumentListItem = ({ symbol, n, start, end, onSelected }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.bottomMargin} variant={'outlined'} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SearchInstrument
            onSelected={value => {
              onSelected(value)
            }}
          ></SearchInstrument>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.smallerFont} variant={'h2'}>
            Ativo
          </Typography>
          <Typography variant={'h3'}>{symbol}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.smallerFont} variant={'h2'}>
            Quantidade
          </Typography>
          <Typography variant={'h3'}>{n}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.smallerFont} variant={'h2'}>
            Inicial
          </Typography>
          <Typography variant={'h3'}>
            <CurrencyFormat
              value={start}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'R$ '}
            />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.smallerFont} variant={'h2'}>
            Final
          </Typography>
          <Typography variant={'h3'}>
            <CurrencyFormat
              value={end}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'R$ '}
            />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SimulationInstrumentListItem
