import React from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
const PortfolioSummary = ({ portfolio }) => {
  const summary = [ // TODO: must calculate portfolio information
    {
      label: 'Return',
      value: 123.45
    },
    {
      label: 'Drawdown',
      value: 123.45
    },
    {
      label: 'Volatility',
      value: 123.45
    },
    {
      label: 'Volatility',
      value: 123.45
    },
    {
      label: 'Beta',
      value: 123.45
    },
    {
      label: 'Beta',
      value: 123.45
    },
    {
      label: 'Correlation',
      value: 123.45
    },
    {
      label: 'Beta',
      value: 123.45
    }
  ]
  return (
    <div>
      <Paper elevation={0} variant={'outlined'}>
        <Grid container spacing={3}>
          {summary.map((item, index) => 
            <Grid key={`info-${item.label}-${index}`} item xs={6}>
              <Typography variant={'h4'}>{item.label}</Typography>
              <Typography variant={'h3'}>{item.value}</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  )
}
export default PortfolioSummary
