import React from 'react'
import PrivateRouter from '../../router/privateRouter'
import { makeStyles } from '@material-ui/core'
import { NavBar } from '../../components/navbar.js/navbar'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(12)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    padding: theme.spacing(1),
  },
  logo: {
    width: theme.spacing(8)
  },
  title: {
    flexGrow: 1
  }
}))

const LargeScreenLayout = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NavBar></NavBar>
      <PrivateRouter />
    </div>
  )
}

export default LargeScreenLayout
