import palette from '../palette'
export default {
  gutterBottom: {
    marginBottom: 8
  },
  h1: {
    color: palette.colors.purple
  },
  h2: {
    letterSpacing: '0.02rem',
    color: palette.colors.purple,
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '2rem'
  },
  h3: {
    color: palette.colors.gray
  },
  h4: {
    color: palette.colors.purple
  },
  h5:{
    color: palette.colors.gray
  }
}
