import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { requestSimulation, updateSimulationParameter } from '../../actions/simulationActions'
import { Grid, Paper, Container, Typography, Tab, Tabs, Button } from '@material-ui/core'
import Fatslider from '../../components/fatSlider/Fatslider'
import { makeStyles } from '@material-ui/styles'
import SimulationInstrumentList from '../../components/simulationInstrumentList/simulationInstrumentList'
import LocalLineChart from '../../components/lineChart/localLineChart'
import Summary from '../../components/summary/summary'
import subDays from 'date-fns/subDays'
import NumberInput from '../../components/numberInput/numberInput'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2rem, 4rem'
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  smallerFont: {
    fontSize: '1.5rem'
  },
  slider: {
    padding: '2rem'
  },
  numberInput: {
    fontSize: '1em',
    color: theme.palette.colors.lightPurple,
    outline: 'none',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: `1px solid ${theme.palette.colors.lightPurple}`
  }
}))

const Simulation = ({ match }) => {
  const { id } = match.params
  const classes = useStyles()
  const today = new Date()
  const rangeButtons = [
    { label: 'Tudo', to: today, from: subDays(today, 8 * 365 - 1) },
    { label: '5 Anos', to: today, from: subDays(today, 4 * 365 - 1) },
    { label: '1 Ano', to: today, from: subDays(today, 365 - 1) }
  ]

  const [balance, setBalance] = useState(0)
  const [alpha, setAlpha] = useState(0)
  const [h, setH] = useState(0)
  const [isSummaryLoaded, setIsSummaryLoaded] = useState(false)
  const [rangeIndex, setRangeIndex] = useState(0)

  const simulation = useSelector(({ simulations }) =>
    simulations.data.find(p => p.id === Number.parseInt(id, 10))
  )
  const localSimulation = useSelector(({ localSimulation }) => localSimulation || {})

  const dispatch = useDispatch()

  const changeRange = (event, index) => {
    setRangeIndex(index)
    dispatch(
      updateSimulationParameter(simulation.id, [
        { key: 'from', value: new Date(rangeButtons[index].from) },
        { key: 'to', value: new Date(rangeButtons[index].to) }
      ])
    )
  }

  useEffect(() => {
    dispatch(requestSimulation(simulation))
    setAlpha(simulation.alpha)
    setH(simulation.h)
    setBalance(simulation.a_percentage)
  }, [])

  return (
    <Container className={classes.root} maxWidth={'xl'}>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <Paper>
            <Typography className={classes.subtitle} variant={'h2'}>
              Simulação
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper variant={'outlined'} elevation={0}>
                  <Typography align={'right'} variant={'h2'}>
                    <NumberInput
                      className={classes.numberInput}
                      value={simulation.initial_value}
                      currency
                      onValue={({ raw }) => {
                        dispatch(
                          updateSimulationParameter(simulation.id, [
                            { key: 'initial_value', value: raw }
                          ])
                        )
                      }}
                    ></NumberInput>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <SimulationInstrumentList
                  simulation={simulation}
                  localSimulation={localSimulation}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={false}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setIsSummaryLoaded(!isSummaryLoaded)
                    console.log('teste')
                  }}
                >
                  {isSummaryLoaded ? 'Gráfico' : 'Sumário'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <Paper>
                <Typography variant="h4" style={{ marginBottom: '2rem' }}>
                  Proporção dos Ativos
                </Typography>
                <Fatslider
                  max={99}
                  balance={balance}
                  onChangeCommitted={(e, value) =>
                    dispatch(
                      updateSimulationParameter(simulation.id, [
                        { key: 'a_percentage', value: value },
                        { key: 'b_percentage', value: 100 - value }
                      ])
                    )
                  }
                  onChange={(e, value) => setBalance(value)}
                ></Fatslider>
                <Grid container justify="space-between">
                  <Typography variant="h4" display="inline">
                    {`${simulation.a_symbol} ${balance}%`}
                  </Typography>
                  <Typography variant="h4" display="inline">
                    {`${simulation.b_symbol} ${100 - balance}%`}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.slider} style={{ marginBottom: '2rem' }}>
                <Grid container justify="space-between">
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4" display="inline">
                      Alpha: {Math.round(alpha * 100)}%
                    </Typography>
                  </Grid>
                  <Grid container style={{ display: 'inline-flex' }}>
                    <Fatslider
                      balance={alpha * 100}
                      onChange={(e, value) => setAlpha(value / 100)}
                      onChangeCommitted={(e, value) =>
                        dispatch(
                          updateSimulationParameter(simulation.id, [
                            { key: 'alpha', value: value / 100 }
                          ])
                        )
                      }
                    ></Fatslider>
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={classes.slider}>
                <Grid container justify="space-between">
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4" display="inline">
                      H: {Math.round(h * 100)}%
                    </Typography>
                  </Grid>
                  <Grid container style={{ display: 'inline-flex' }}>
                    <Fatslider
                      balance={h * 100}
                      onChange={(e, value) => {
                        setH(value / 100)
                      }}
                      onChangeCommitted={(e, value) =>
                        dispatch(
                          updateSimulationParameter(simulation.id, [
                            { key: 'h', value: value / 100 }
                          ])
                        )
                      }
                    ></Fatslider>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Tabs
                component={Paper}
                variant="fullWidth"
                value={rangeIndex}
                onChange={changeRange}
                orientation="vertical"
                textColor="primary"
                indicatorColor="primary"
              >
                {rangeButtons.map(button => (
                  <Tab
                    key={`button-${button.label}`}
                    className={classes.button}
                    label={button.label}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {isSummaryLoaded ? (
                <Summary localSimulation={localSimulation} />
              ) : (
                <Paper>
                  <LocalLineChart localSimulation={localSimulation} loading={false} />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default withRouter(Simulation)
