import React from 'react'
import CurrencyFormat from 'react-currency-format'

import './summary.css'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody
} from '@material-ui/core'

const Summary = ({ localSimulation, loading = false }) => {
  return !localSimulation.summary && !localSimulation.chartData ? null : (
    <div>
      <TableContainer component={Paper}>
        <Typography variant={'h2'}>Sumario</Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant={'h4'}>Com balanceamento</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h4'}>Sem balanceamento</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h4'}>{localSimulation.summary.benchmark}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant={'h4'}>inicial</Typography>
              </TableCell>
              {[null, null, null].map((_, index) => (
                <TableCell key={`initial-${index}`}>
                  <Typography variant={'h3'}>
                    <CurrencyFormat
                      value={localSimulation.summary.initial_value}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={'h4'}>final</Typography>
              </TableCell>
              {[
                localSimulation.chartData[localSimulation.chartData.length - 1].totalBalanced,
                localSimulation.chartData[localSimulation.chartData.length - 1].total,
                localSimulation.chartData[localSimulation.chartData.length - 1].cValue
              ].map((value, index) => (
                <TableCell key={`final-${index}`}>
                  <Typography variant={'h3'}>
                    <CurrencyFormat
                      value={value}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'R$ '}
                    />
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={'h4'}>maior valor</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>
                  <CurrencyFormat
                    value={Math.max(...localSimulation.chartData.map(data => data.totalBalanced))}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>
                  <CurrencyFormat
                    value={Math.max(...localSimulation.chartData.map(data => data.total))}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>
                  <CurrencyFormat
                    value={Math.max(...localSimulation.chartData.map(data => data.cValue))}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant={'h4'}>menor valor</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>
                  <CurrencyFormat
                    value={Math.min(...localSimulation.chartData.map(data => data.totalBalanced))}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>
                  <CurrencyFormat
                    value={Math.min(...localSimulation.chartData.map(data => data.total))}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>
                  <CurrencyFormat
                    value={Math.min(...localSimulation.chartData.map(data => data.cValue))}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={'h4'}>rendimento</Typography>
              </TableCell>
              <TableCell className="bold">
                <Typography variant={'h3'}>
                  {(
                    (localSimulation.chartData[localSimulation.chartData.length - 1].totalBalanced /
                      localSimulation.summary.initial_value -
                      1) *
                    100
                  ).toFixed(2)}
                  %
                </Typography>
              </TableCell>
              <TableCell className="bold">
                <Typography variant={'h3'}>
                  {(
                    (localSimulation.chartData[localSimulation.chartData.length - 1].total /
                      localSimulation.summary.initial_value -
                      1) *
                    100
                  ).toFixed(2)}
                  %
                </Typography>
              </TableCell>
              <TableCell className="bold">
                <Typography variant={'h3'}>
                  {(
                    (localSimulation.chartData[localSimulation.chartData.length - 1].cValue /
                      localSimulation.summary.initial_value -
                      1) *
                    100
                  ).toFixed(2)}
                  %
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant={'h4'}># de trades</Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'h3'}>{localSimulation.summary.trades}</Typography>
              </TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Summary
