import axios from 'axios'
// import mockAll from '../mocks'

//const production =
//  process.env.NODE_ENV === 'production' && process.env.NOW_GITHUB_COMMIT_REF === 'master'

const production = false // sempre busca no staging por enquanto

let api
if (!production) {
  // mockAll()
  api = axios.create({ baseURL: 'https://api.oplab.com.br/v2' })
} else {
  api = axios.create({
    baseURL: 'https://api.oplab.com.br/v2'
  })
}

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('access-token')
  if (token) {
    config.headers['access-token'] = token
  }
  return config
})

export default api
