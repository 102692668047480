export const REQUEST_MANAGED_PORTFOLIOS = Symbol('REQUEST_MANAGED_PORTFOLIOS')
export const REQUEST_UNMANAGED_PORTFOLIOS = Symbol('REQUEST_MANAGED_PORTFOLIOS')
export const FULLFILL_MANAGED_PORTFOLIOS = Symbol('FULLFILL_MANAGED_PORTFOLIOS')
export const FULLFILL_UNMANAGED_PORTFOLIOS = Symbol('FULLFILL_MANAGED_PORTFOLIOS')
export const REQUEST_CREATE_MANAGED_PORTFOLIO = Symbol('REQUEST_CREATE_MANAGED_PORTFOLIO')
export const REQUEST_CREATE_UNMANAGED_PORTFOLIO = Symbol('REQUEST_CREATE_UNMANAGED_PORTFOLIO')
export const ADD_MANAGED_PORTFOLIO_TO_LIST = Symbol('ADD_MANAGED_PORTFOLIO_TO_LIST')
export const ADD_UNMANAGED_PORTFOLIO_TO_LIST = Symbol('ADD_UNMANAGED_PORTFOLIO_TO_LIST')
export const REQUEST_DELETE_MANAGED_PORTFOLIO = Symbol('REQUEST_DELETE_MANAGED_PORTFOLIO')
export const REQUEST_DELETE_UNMANAGED_PORTFOLIO = Symbol('REQUEST_DELETE_UNMANAGED_PORTFOLIO')
export const REMOVE_MANAGED_PORTFOLIO_FROM_LIST = Symbol('REMOVE_MANAGED_PORTFOLIO_FROM_LIST')
export const REMOVE_UNMANAGED_PORTFOLIO_FROM_LIST = Symbol('REMOVE_UNMANAGED_PORTFOLIO_FROM_LIST')
export const UPDATE_MANAGED_PORTFOLIO_ON_LIST = Symbol('UPDATE_MANAGED_PORTFOLIO_ON_LIST')
export const UPDATE_UNMANAGED_PORTFOLIO_ON_LIST = Symbol('UPDATE_UNMANAGED_PORTFOLIO_ON_LIST')
export const ADD_INSTRUMENT_TO_PORTFOLIO = Symbol('ADD_INSTRUMENT_TO_PORTFOLIO')
export const UPDATE_INSTRUMENT_TO_PORTFOLIO = Symbol('UPDATE_INSTRUMENT_TO_PORTFOLIO')
export const DELETE_INSTRUMENT_TO_PORTFOLIO = Symbol('DELETE_INSTRUMENT_TO_PORTFOLIO')
export const REQUEST_UPDATE_UNMANAGED_PORTFOLIO = Symbol('REQUEST_UPDATE_UNMANAGED_PORTFOLIO')
export const UPDATE_UNMANAGED_INITIAL_VALUE = Symbol('UPDATE_UNMANAGED_INITIAL_VALUE')
export const REQUEST_PORTFOLIOS = Symbol('REQUEST_PORTFOLIOS')
export const FULFILL_PORTFOLIOS = Symbol('FULFILL_PORTFOLIOS')
export const REQUEST_CREATE_PORTFOLIO = Symbol('REQUEST_CREATE_PORTFOLIO')
export const REQUEST_DELETE_PORTFOLIO = Symbol('REQUEST_DELETE_PORTFOLIO')