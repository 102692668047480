import palette from '../palette'
export default {
    textColorPrimary:{
            color: palette.colors.purple,
        '&$selected': {
            color: palette.colors.white,
            backgroundColor: palette.colors.purple	
        }
    }
}
