import {
  FULLFILL_SIMULATION,
  REQUEST_SIMULATION,
  CHANGE_SIMULATION_INTERVAL,
  FULFILL_SIMULATIONS,
  UPDATE_SIMULATION_PARAMETERS
} from '../constants/simulationConstants'

export default (state = { working: false, interval: 0 }, action) => {
  const { type } = action
  switch (type) {
  case UPDATE_SIMULATION_PARAMETERS:
    return {
      ...state,
      data: state.data.map(simulation => {
        const s = {...simulation}
        if (simulation.id === action.id) {
          action.parameters.forEach(({key, value}) => {
            s[key] = value
          })
        }
        return s
      })
    }
  case CHANGE_SIMULATION_INTERVAL:
    return { ...state, interval: action.interval }
  case REQUEST_SIMULATION:
    return { ...state, working: true }
  case FULFILL_SIMULATIONS:
    return { data: action.response, working: false }
  case FULLFILL_SIMULATION:
    return { ...action.response, working: false, interval: state.interval }
  default:
    return { ...state }
  }
}
