import React from 'react'
import { Line } from 'react-chartjs-2'
// import Hammer from 'hammerjs'
// import * as zoom from 'chartjs-plugin-zoom'
import { categoryColor } from '../../utils/ui'


const LocalLineChart = ({ localSimulation, loading }) => {
  const build = () => {
    if (!localSimulation.chartData) return null
    const labels = localSimulation.chartData.map(v => v.date)
    const totalBalanced = localSimulation.chartData.map(v => v.totalBalanced.toFixed(2))
    const total = localSimulation.chartData.map(v => v.total.toFixed(2))
    const benchmarkTotal = localSimulation.chartData.map(v => v.cValue.toFixed(2))
    return {
      labels,
      datasets: [
        {
          fill: false,
          label: 'Balanceado',
          data: totalBalanced,
          borderWidth: 1,
          borderColor: categoryColor(5),
          pointRadius: 0
        },
        {
          fill: false,
          label: 'Sem balanceamento',
          data: total,
          borderWidth: 1,
          borderColor: '#666',
          pointRadius: 0
        },
        {
          fill: false,
          label: localSimulation.summary.benchmark,
          data: benchmarkTotal,
          borderWidth: 1,
          borderColor: categoryColor(4),
          pointRadius: 0
        }
      ],
      options: {
        plugins: {
          zoom: {
            // Container for pan options
            pan: {
              // Boolean to enable panning
              enabled: true,
  
              // Panning directions. Remove the appropriate direction to disable
              // Eg. 'y' would only allow panning in the y direction
              mode: 'xy'
            },
  
            // Container for zoom options
            zoom: {
              // Boolean to enable zooming
              enabled: true,
  
              // Zooming directions. Remove the appropriate direction to disable
              // Eg. 'y' would only allow zooming in the y direction
              mode: 'xy'
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label || ''
              return `${label}: R$ ${(Math.round(tooltipItem.yLabel * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                .replace(/(\.)(\d+)$/gm, ',$2')}`
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => 'R$ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              }
            }
          ],
          xAxes: [
            {
              type: 'time',
              ticks: {
                autoSkip: true,
                maxTicksLimit: 12
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    }
  }

  const chart = build()

  return (
    <div style={{ height: '90%', maxHeight: 500 }}>
      {chart && (
        <Line
          data={chart}
          options={{ responsive: true, maintainAspectRatio: true, ...chart.options }}
        ></Line>
      )}
    </div>
  )
}
  
export default LocalLineChart