import { FULFILL_LOGIN, LOGOUT, LOGIN_FAILED } from '../constants/loginConstants'

export default (state = { failure: false }, action) => {
  const { type } = action
  switch (type) {
  case FULFILL_LOGIN:
    return { ...action.login, failure: false }
  case LOGOUT:
    return {}
  case LOGIN_FAILED:
    return { failure: true }
  default:
    return { ...state }
  }
}
