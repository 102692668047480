import lf from 'localforage'
import { getChartData } from '../services/instrumentServices'
export async function getAssetData(request) {
  const isRelevant = ({ from: localFrom, to: localTo }, [, from, to]) => {
    const localDifference = new Date(localTo) - new Date(localFrom)
    const requestedDifference = new Date(to) - new Date(from)
    return localDifference - requestedDifference === 0
  }
  try {
    const [symbol, from, to] = request
    const localAsset = await lf.getItem(symbol)
    if (localAsset && isRelevant(localAsset, request)) {
      return localAsset
    }
    else {
      const { data } = await getChartData(...request)
      await lf.setItem(symbol, { from, to, ...data, data: data.data }) // saves to IndexedDB/LocalStorage
      return { from, to, ...data, data: data.data }
    }
  }
  catch (e) {
    console.log(e)
  }
}
