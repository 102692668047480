import { UPDATE_SIMULATION_PARAMETERS, REQUEST_SIMULATION, FULLFILL_SIMULATION, REQUEST_DELETE_SIMULATION, CHANGE_SIMULATION_INTERVAL, FULFILL_LOCAL_SIMULATION, REQUEST_CREATE_NEW_SIMULATION, REQUEST_SIMULATIONS, FULFILL_SIMULATIONS } from '../constants/simulationConstants'

export const requestSimulation = simulation => ({ type: REQUEST_SIMULATION, simulation })
export const requestDeleteSimulation = id => ({ type: REQUEST_DELETE_SIMULATION, id })
export const requestSimulations = () => ({type: REQUEST_SIMULATIONS})
export const fullfillSimulation = response => ({ type: FULLFILL_SIMULATION, response })
export const fulfillSimulations = response => ({ type: FULFILL_SIMULATIONS, response })
export const changeSimulationInterval = (portfolio, interval) => ({ type: CHANGE_SIMULATION_INTERVAL, portfolio, interval })
export const fulfillLocalSimulation = (localSimulation) => ({type: FULFILL_LOCAL_SIMULATION, localSimulation})
export const requestCreateNewSimulation = name => ({type: REQUEST_CREATE_NEW_SIMULATION, name})
export const updateSimulationParameter = (id, parameters) => ({type: UPDATE_SIMULATION_PARAMETERS, id, parameters})