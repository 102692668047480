import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LargeScreenLayout from './largeScreenLayout'
import { READY } from '../../constants/applicationConstants'
import { requestSimulations } from '../../actions/simulationActions'
import { requestPortfolios } from '../../actions/portfolioActions'

const MainLayout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestSimulations())
    dispatch(requestPortfolios())
  }, [dispatch])

  const status = useSelector(({ application }) => application.status)

  return status !== READY ? null : (
    <LargeScreenLayout></LargeScreenLayout>
  )
}
export default MainLayout
