import { FULFILL_SIMULATIONS } from '../constants/simulationConstants'
import { INITIALIZED, READY } from '../constants/applicationConstants'

export default (state = { status: INITIALIZED }, action) => {
  const { type } = action
  switch (type) {
  case FULFILL_SIMULATIONS:
    return state.status !== READY ? { ...state, status: state.status + 1 } : { ...state }
  default:
    return { ...state }
  }
}
