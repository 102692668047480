import React, { useEffect, useState } from 'react'
import { history } from './store'
import { useDispatch, useSelector } from 'react-redux'
import AppRouter from './router/appRouter'
import { fulfillLogin } from './actions/loginActions'
import { authorize, accessToken } from './services/loginServices'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core'

const App = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!accessToken()) {
      setLoading(false)
      return
    }

    const login = async () => {
      const user = await authorize()
      if (user) dispatch(fulfillLogin(user))
      setLoading(false)
    }

    login()
  }, [dispatch])

  const loader = loading && (
    // <Dimmer active inverted>
    //   <Loader />
    // </Dimmer>
    <div>loader</div>
  )

  const user = useSelector(({ login }) => login)
  const theme = useSelector(({ theme }) => theme)

  return (<React.Fragment>
    {loading ? loader : 
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {/* <Button color="primary" variant="outlined" onClick={()=>{
          dispatch({type: TOGGLE_THEME})
        }}>
          Test
        </Button> */}
        <AppRouter isLoggedIn={user.id} history={history}></AppRouter>
      </ThemeProvider>
    }
  </React.Fragment>)
}

export default App
