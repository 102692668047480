import { call, put, takeLatest, select } from 'redux-saga/effects'
import { requestSimulations, requestSimulation } from '../actions/simulationActions'
import { simulate, addSimulation, list, deleteSimulation, updateSimulation } from '../services/simulationServices'
import {
  REQUEST_SIMULATION,
  REQUEST_CREATE_NEW_SIMULATION,
  REQUEST_SIMULATIONS,
  REQUEST_DELETE_SIMULATION,
  UPDATE_SIMULATION_PARAMETERS
} from '../constants/simulationConstants'
import {
  fulfillLocalSimulation,
  fulfillSimulations
} from '../actions/simulationActions'

function* simulationHandler({ simulation }) {
  try {
    const response = yield call(simulate, simulation) // sync
    if (response) {
      yield put(fulfillLocalSimulation(response))
    }
  } catch (e) {
    console.log(e)
    //FIXME show a toast or some other shit
  }
}

function* updateSimulationHandler({id}) {
  try {
    const simulation = yield select(({ simulations }) => simulations.data.find(s => s.id === id))
    yield put(requestSimulation(simulation))
    yield call(updateSimulation, simulation)
  } catch (e) {
    console.log(e)
  }
}

function* simulationsHandler() {
  try {
    const response = yield call(list)
    if (response) {
      yield put(fulfillSimulations(response))
    }
  } catch (e) {
    //FIXME show a toast or some other shit
  }
}

function* newSimulationHandler({ name }) {
  yield call(addSimulation, name)
  yield put(requestSimulations())
}

function* deleteSimulationHandler({ id }) {
  yield call(deleteSimulation, id)
  yield put(requestSimulations())
}

export default [
  takeLatest(REQUEST_SIMULATION, simulationHandler),
  takeLatest(REQUEST_CREATE_NEW_SIMULATION, newSimulationHandler),
  takeLatest(REQUEST_DELETE_SIMULATION, deleteSimulationHandler),
  takeLatest(REQUEST_SIMULATIONS, simulationsHandler),
  takeLatest(UPDATE_SIMULATION_PARAMETERS, updateSimulationHandler)
]