import api from './interceptor'
import Oplab from '@oplab-team/oplabjs'
import { getAssetData } from '../utils/getAssetData'

export const addSimulation = async name =>
  (
    await api.post('/balancing_simulations', {
      balancing_simulation: {
        name,
        a_symbol: 'MGLU3',
        b_symbol: 'LFT',
        benchmark_symbol: 'IBOV',
        description: '.',
        alpha: 0.3,
        h: 1,
        from: '201301010000',
        to: '201901010000',
        initial_value: 100000,
        a_percentage: 50,
        b_percentage: 50
      }
    })
  ).data
export const updateSimulation = async simulation => (await api.put(`/balancing_simulations/${simulation.id}`, {balancing_simulation: simulation})).data
export const deleteSimulation = async id => (await api.delete(`balancing_simulations/${id}`)).data
export const list = async () => (await api.get('/balancing_simulations')).data
export const simulate = async ({a_symbol, b_symbol, benchmark_symbol, from, to, alpha, h, initial_value, a_percentage, b_percentage}) => {
  const [aData, bData, cData] = await Promise.all([
    getAssetData([a_symbol, from, to]),
    getAssetData([b_symbol, from, to]),
    getAssetData([benchmark_symbol, from, to])]
  )
  let initialAN = initial_value * a_percentage / 100 / aData.data[0].close, aN = initialAN
  let initialBN = (initial_value - aN * aData.data[0].close) / bData.data[0].close, bN = initialBN
  let initialCN = initial_value / cData.data[0].close
  let aDaily = aData.data
  let bDaily = bData.data
  if (aDaily.length > bDaily.length){
    aDaily = aDaily.slice(aDaily.length - bDaily.length)
  } else {
    bDaily = bDaily.slice(bDaily.length - aDaily.length)
  }

  const consolidatedData = aDaily.map((data, index) => {
    return { date: data.time, a: data.close, b: bDaily[index].close, c: cData.data[index].close }
  })

  const summary = {
    trades: 0
  }

  const chartData = consolidatedData.map(day => {
    let dw = new Oplab.DynamicWallet(
      [
        { proportion: a_percentage, name: a_symbol },
        { proportion: b_percentage, name: b_symbol }
      ],
      [
        { n: aN, value: day.a, name: a_symbol },
        { n: bN, value: day.b, name: b_symbol }
      ],
      alpha,
      h
    )
    summary.trades += dw._orders.length // updates summary trades
    dw.balance()
    const aBalanced = dw.assets.find(asset => asset.name === a_symbol)
    const bBalanced = dw.assets.find(asset => asset.name === b_symbol)

    aN = aBalanced.n
    bN = bBalanced.n
    
    return {
      date: day.date,
      totalBalanced: day.a * aN + day.b * bN,
      aClose: day.a,
      total: day.a * initialAN + day.b * initialBN,
      aValue: day.a * initialAN,
      aBalancedValue: day.a * aBalanced.n,
      aBalancedN: aN,
      bClose: day.b,
      bValue: day.b * initialBN,
      bBalancedValue: day.b * bBalanced.n,
      bBalancedN: bN,
      cClose: day.c,
      cValue: day.c * initialCN
    }
  })
  summary.initialAN = initialAN
  summary.initialBN = initialBN
  summary.benchmark = benchmark_symbol
  summary.initial_value = initial_value

  return {summary, chartData}
}
