import palette from '../palette'
export default {
  root: {
    cursor: 'pointer'
  },
  primary: {
    color: palette.colors.lightPurple,
    fontSize: '2rem'
  },
  secondary: {
    color: palette.colors.lightPurple,
    fontSize: '1.5rem'
  }
}