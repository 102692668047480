export default {
  htmlFontSize: 8, // TODO: not working
  fontSize: 8,

  fontFamily: [
    'Noto Sans'
  ].join(','),
  
  h1: {
    fontSize: '3rem',
    fontStyle: 'normal',
    lineHeight: '4rem',
    fontWeight: 600,
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '2rem'
  },
  h3: {
    fontWeight: 'normal',
    fontSize: '2rem',
    lineHeight: '2rem'
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '2rem'
  },
   h5: {
    fontWeight: 'normal',
    fontSize: '1.5rem',
    lineHeight: '2rem'
   },
  // h6: {
  //   fontWeight: 500,
  //   fontSize: '1rem',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '20px'
  // },
  // subtitle1: {
  //   fontSize: '16px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '25px'
  // },
  // subtitle2: {
  //   fontWeight: 400,
  //   fontSize: '14px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px'
  // },
  // body1: {
  //   fontSize: '1rem',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '21px'
  // },
  // body2: {
  //   fontSize: '.8rem',
  //   letterSpacing: '-0.04px',
  //   lineHeight: '18px'
  // },
  // button: {
  //   fontSize: '14px'
  // },
  // caption: {
  //   fontSize: '11px',
  //   letterSpacing: '0.33px',
  //   lineHeight: '13px'
  // },
  // overline: {
  //   fontSize: '11px',
  //   fontWeight: 500,
  //   letterSpacing: '0.33px',
  //   lineHeight: '13px',
  //   textTransform: 'uppercase'
  // },
  // p: {
  //   color: 'red'
  // }
}
