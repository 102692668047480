import palette from '../palette'

export default {
  contained: {
    fontSize: '16px',
    lineHeight: '16px',
    width: '100%',
    padding: '16px',
    textTransform: 'none'
  },
  outlinedPrimary: {
    fontSize: '16px',
    lineHeight: '16px',
    width: '100%',
    padding: '16px',
    borderColor: palette.colors.white,
    color: palette.colors.white,
    backgroundColor: 'transparent',
    textTransform: 'none',
    '&:hover': {
      color: palette.colors.black,
      backgroundColor: palette.colors.white,
      borderColor: palette.colors.white
    }
  }
}
