import { colors } from '@material-ui/core'

export const purple = '#471053'
export const lightPurple = '#5F246D'
export const orange = '#FF6B00'
export const blue = '#0E8BFF'
export const green = '#2ADC26'
export const yellow = '#FFDB38'
export const red = '#F8144A'
export const black = '#2A292D'
export const steel = '#3B3F45'
export const gray = '#727272'
export const lightSmoke = '#F0F1F5'
export const white = '#FFFFFF'

export default {
  colors: {
    purple,
    lightPurple,
    orange,
    blue,
    green,
    yellow,
    red,
    black,
    steel,
    gray,
    lightSmoke,
    white
  },
  black,
  // white,
  primary: {
    contrastText: white,
    dark: lightPurple,
    main: purple,
    light: lightPurple
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  blob: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  // text: {
  //   primary: colors.blueGrey[900],
  //   secondary: colors.grey[600],
  //   link: colors.blue[600]
  // },
  background: {
    default: lightPurple,
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
}
