import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Redirect, Switch } from 'react-router-dom'
import MainLayout from '../containers/layouts/mainLayout'
import Login from '../containers/login/login'
import Signup from '../containers/signup/signup'
import { logout } from '../actions/loginActions'


const RenderRoot = props => {
  const isLoggedIn = useSelector(({ login }) => (login || {}).id )

  if (isLoggedIn) {
    return (<MainLayout />)
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />
  )
}

const RenderLogin = props => {
  const isLoggedIn = useSelector(({ login }) => (login || {}).id )

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    )
  }

  return <Login {...props} />
}

const RenderSignup = props => {
  const isLoggedIn = useSelector(({ login }) => (login || {}).id )

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    )
  }

  return <Signup {...props} />
}

const RenderLogout = props => {
  useDispatch()(logout())

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />
  )
}

const Counter = function(props){
  console.log(props)
  return <div>
    <p>{props.count}</p>
    {props.children}
  </div>
}


const Teste = () => {
  const [count, setCount]= useState(0)
  return (<div>
    <Counter count={count}>
      <p><button onKeyPress={()=>{
        console.log('apeertou!!!')
        setCount(count - 1)
      }} onClick={()=>{
        console.log('clicou')
        setCount(count + 1)
      }}>AUMENTA!</button></p>
    </Counter>
  </div>)
}

const AppRouter = ({history}) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact={true} path="/teste" component={Teste}/>
        <Route exact={true} path="/login" component={RenderLogin} />
        <Route exact={true} path="/signup" component={RenderSignup} />
        <Route exact={true} path="/logout" component={RenderLogout} />
        <Route path="/" component={RenderRoot} />
        <Route component={null} />
      </Switch>
    </ConnectedRouter>
  )
}

export default AppRouter
