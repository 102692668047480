import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './login.css'
import { authenticateLogin } from '../../actions/loginActions'
import { validateEmail, validatePassword } from '../../utils'
import { Link } from 'react-router-dom'
import { Typography, TextField, Button, Box, Grid, Paper, Snackbar, makeStyles } from '@material-ui/core'
import logo from '../../images/logo.png'
import crazy from '../../images/crazy.png'
import MuiAlert from '@material-ui/lab/Alert'

const useStyles = makeStyles(({palette}) => {
  return {
    root: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: palette.colors.lightPurple
    }
  }
})

const Login = () => {
  const classes = useStyles()
  const [credentials, setCredentials] = useState({})
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const dispatch = useDispatch()

  const credentialsHandler = ({ target: { value, name } }) => {
    if (name === 'email') {
      setIsValidEmail(validateEmail(value))
    } else if (name === 'password') {
      setIsValidPassword(validatePassword(value))
    }
    setCredentials({ ...credentials, [name]: value })
  }

  const loginHandler = () => {
    dispatch(authenticateLogin(credentials))
  }

  const { failure } = useSelector(({ login }) => login || {})

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  // const theme = useSelector(theme => theme)

  return (
    <div className={classes.root}>
      <Box m={8}>
        <Snackbar open={failure}>
          <Alert severity="error">This is a success message!</Alert>
        </Snackbar>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Box>
              <img alt="Oplab Logo" src={logo}></img>
            </Box>
            <Box mt={6} align="center">
              <img alt="Arte Abstrata" src={crazy}></img>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Box p={11} pb={23}>
                <Typography variant="h1" style={{ marginBottom: '1rem' }}>
                  Welcome,
                </Typography>
                <Typography variant="h3" style={{ marginBottom: '3rem' }}>
                  Sign in to continue
                </Typography>
                <form noValidate autoComplete="off" size="large">
                  <TextField
                    margin="normal"
                    name="email"
                    onChange={credentialsHandler}
                    label="E-mail"
                    variant="outlined"
                  />
                  <TextField
                    margin="normal"
                    name="password"
                    onChange={credentialsHandler}
                    label="Senha"
                    variant="outlined"
                    type="password"
                  />
                  <Box mt={2}>
                    <Button
                      disabled={!isValidEmail || !isValidPassword}
                      color="primary"
                      variant="outlined"
                      onClick={loginHandler}
                    >
                      Entrar
                    </Button>
                  </Box>
                </form>
                <Typography>
                  Não tem conta? <Link to="/signup">Cadastre-se</Link>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Login
