import React, { useEffect } from 'react'
import SimulationInstrumentListItem from '../simulationInstrumentListItem/simulationInstrumentListItem'
import { List } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { updateSimulationParameter } from '../../actions/simulationActions'

const SimulationInstrumentList = ({ simulation, localSimulation }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    console.log(simulation)
    console.log(localSimulation)
  }, [localSimulation, simulation])
  return (
    <List>
      <SimulationInstrumentListItem
        onSelected={asset => {
          if (asset && asset.symbol) {
            dispatch(
              updateSimulationParameter(simulation.id, [{ key: 'a_symbol', value: asset.symbol }])
            )
          }
        }}
        symbol={simulation.a_symbol}
        n={localSimulation.summary ? Math.ceil(localSimulation.summary.initialAN) : 0}
        start={localSimulation.chartData ? localSimulation.chartData[0].aBalancedValue : 0 }
        end={localSimulation.chartData ? localSimulation.chartData[localSimulation.chartData.length -1].aBalancedValue : 0 }
      />
      <SimulationInstrumentListItem
        onSelected={asset => {
          if (asset && asset.symbol) {
            dispatch(
              updateSimulationParameter(simulation.id, [{ key: 'b_symbol', value: asset.symbol }])
            )
          }
        }}
        symbol={simulation.b_symbol}
        n={localSimulation.summary ? Math.ceil(localSimulation.summary.initialBN) : 0}
        start={localSimulation.chartData ? localSimulation.chartData[0].bBalancedValue : 0 }
        end={localSimulation.chartData ? localSimulation.chartData[localSimulation.chartData.length -1].bBalancedValue : 0 }
      />
    </List>
  )
}

export default SimulationInstrumentList
