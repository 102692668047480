import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grow
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid #471053'
  },
  button: {
    borderRadius: '0px'
  },
  item: {
    right: '0px'
  }
}))

function OplabItem({ item, ...props }) {
  const classes = useStyles()
  const [isDisabled, setIsDisabled] = useState(false)
  const [confirm, setConfirm] = useState(false)
  useEffect(() => {
    setIsDisabled(false)
  }, [props.items])
  return !item ? null : (
    <ListItem className={classes.root}>
      <ListItemText
        onClick={() => {
          props.onClick()
        }}
        primary={item.name ? item.name : 'Item'}
        secondary={`Criado em: ${item.created_at ? item.created_at : item['created-at']}`}
      />
      <ListItemSecondaryAction className={classes.item}>
        {!confirm ? (
          <IconButton
            disabled={isDisabled}
            style={{ right: 16 }}
            onClick={() => {
              setConfirm(true)
            }}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon fontSize="large" color="primary" />
          </IconButton>
        ) : (
          <Grow direction="left" in={true} mountOnEnter unmountOnExit>
            <Button
              disabled={isDisabled}
              size="large"
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setConfirm(false)
                props.onDelete()
                setIsDisabled(true)
              }}
            >
              Delete
            </Button>
          </Grow>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default function OplabList({ items, ...props }) {
  return (
    <List>
      {!items
        ? null
        : items.map((item, i) => (
          <OplabItem
            items={items}
            key={`${item.name}-${i}`}
            onClick={() => {
              props.onClick(item)
            }}
            onDelete={() => {
              props.onDelete(item)
            }}
            item={item}
          />
        ))}
    </List>
  )
}
