import palette from '../palette'
export default {
    thumb:{
        border: "1px solid #471053",
        boxSizing: "border-box",
        boxShadow:
          "0px 1px 2px rgba(31, 9, 42, 0.34), 0px 2px 4px rgba(95, 36, 109, 0.24)",
        borderRadius: '4px',
        color: palette.colors.lightPurple,
        width: '2.5rem',
        height: '2rem'
    },
}