import palette from '../palette'

export default {
  contained: {
    fontSize: '16px',
    lineHeight: '16px',
    width: '100%',
    padding: '16px',
    textTransform: 'initial'
  },
  outlinedPrimary: {
    fontSize: '16px',
    lineHeight: '16px',
    width: '100%',
    padding: '16px',
    borderColor: palette.colors.purple,
    textTransform: 'initial',
    '&:hover': {
      color: palette.colors.white,
      backgroundColor: palette.colors.purple
    }
  }
}
